import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Contact from '../components/contact'

import { useStaticQuery, graphql } from 'gatsby'

import Lightbox from '../components/Lightbox'

import sign from '../images/08_2.svg'

const captions = [
  { author: "Štěpán" },
  { author: "Štěpán" },
  { author: "Štěpán" },
  { author: "Štěpán" },
  { author: "Štěpán" },
  { author: "Štěpán" },
  { author: "Štěpán" },
  { author: "Štěpán" },
  { author: "Štěpán" },
  { author: "Štěpán" },
  { author: "Štěpán" },
  { author: "Štěpán" },
  { author: "Štěpán" },
  { author: "Štěpán" },
  { author: "Štěpán" },
  { author: "Štěpán" },
  { author: "Štěpán" },
  { author: "Štěpán" },
];

const IndexPage = (props) => (
  <Layout>
    <SEO title="Home" />

    <div className="text-center">
      <h1>Keep it creative</h1>
    </div>

    <Contact />

    <div className="curl">
      <img src={sign} width="20%" />
    </div>

    <Lightbox
      images={props.data.allFile.edges}
      captions={captions}
    />

    <Contact />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    allFile (sort: {fields: [name], order: ASC}) {
      edges {
        node {
          relativeDirectory
          childImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
