import React from 'react'
import ReactDOM from 'react-dom'

const Modal = ({ children, visible, onKeyUp, onClick }) => {
  if (!visible) {
    return null
  }

  return (
    <div
      className="modal"
      onKeyUp={onKeyUp}
      onClick={(e) => {
        if (e.target === document.querySelector('.modal')) {
          onClick()
        }
      }}
    >
      <div className="modal-backdrop" />

      <div className="modal-content" onClick={(e) => {
        if (e.target === document.querySelector('.modal-content')) {
          onClick()
        }
      }}>
        {children}
      </div>
    </div>
  )
}

export default Modal
