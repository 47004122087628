import React, { Component } from 'react'
import Img from 'gatsby-image'

import Modal from './Modal'

class Lightbox extends Component {
  state = {
    showLightbox: false,
    selectedImage: 0,
  }

  componentDidMount = () => {
    window.addEventListener('keyup', this.handleKeyUp, false)
  }

  componentWillUnmount = () => {
    window.removeEventListener('keyup', this.handleKeyUp, false)
  }

  handleClick = (e, index) => {
    e.preventDefault()
    this.setState({
      showLightbox: !this.state.showLightbox,
      selectedImage: index,
    })
  }

  closeModal = () => {
    this.setState({ showLightbox: false })
  }

  goBack = () => {
    this.setState({ selectedImage: Math.max(this.state.selectedImage - 1, 0) })
  }

  goForward = () => {
    this.setState({
      selectedImage: (this.state.selectedImage + 1) % this.props.images.length,
    })
  }

  handleKeyUp = (e) => {
    e.preventDefault()
    const { keyCode } = e
    if (this.state.showLightbox) {
      if (keyCode === 37) {
        // Left Arrow Key
        if (this.state.selectedImage > 0) {
          //   this.setState({ selectedImage: this.state.selectedImage - 1 })
          this.goBack()
        }
      }
      if (keyCode === 39) {
        // Right Arrow Key
        if (this.state.selectedImage < this.props.images.length - 1) {
          //   this.setState({ selectedImage: this.state.selectedImage + 1 })
          this.goForward()
        }
      }
      if (keyCode === 27) {
        // Escape key
        this.setState({ showLightbox: false })
      }
    }
  }

  render = () => (
    <>
      <div style={{ maxWidth: '75rem', margin: '0 auto' }}>
        <div className="grid">
          {this.props.images.map((edge, i) => {
            const image = edge.node.childImageSharp
            return (
              <div key={image.id} onClick={(e) => this.handleClick(e, i)}>
                <Img
                  className={i % 2 === 0 ? 'gold' : 'green'}
                  fluid={image.fluid}
                  alt=""
                />
              </div>
            )
          })}
        </div>
      </div>

      <Modal
        visible={this.state.showLightbox}
        onKeyUp={(e) => this.handleKeyDown(e)}
        onClick={this.closeModal}
      >
        <div className="flex-column">
          {/* sizes={this.props.images[this.state.selectedImage].node.childImageSharp.sizes} */}
          <span onClick={this.goForward}>
          <Img
            className="lightbox-image"
            style={{maxHeight: '80vh'}}
            // style={{position: 'initial', display: 'flex', justifyContent: 'center', height: '80vh'}}
            imgStyle={{ width: 'auto', position: 'relative', maxHeight: '-webkit-fill-available' }}
            fluid={
              this.props.images[this.state.selectedImage].node.childImageSharp
                .fluid
            }
          />
          </span>
          <div className="lightbox-controls">
            <button onClick={this.closeModal}>&times;</button>
            <div className="lightbox-leftright">
              <button
                onClick={this.goBack}
                disabled={this.state.selectedImage === 0}
              >
                &lt;
              </button>
              <button
                onClick={this.goForward}
                disabled={
                  this.state.selectedImage === this.props.images.length - 1
                }
              >
                &gt;
              </button>
            </div>
          </div>
          <p>by {this.props.captions[this.state.selectedImage].author}</p>
        </div>
      </Modal>
    </>
  )
}

export default Lightbox
