import React from 'react';

const contact = () => (
    <div className="text-center contact">
      <a href="tel:736726812">736 726 812</a><br/>
      <a href="mailto:we@keepitcreative.cz">we@keepitcreative.cz</a>
    </div>
);

export default contact;
